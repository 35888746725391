import { toast } from 'react-toastify';
import { call, put, takeLatest, all, select } from 'redux-saga/effects';

import * as AuthActions from '../auth/actions';
import * as UserActions from './actions';
import api from '~/services/api';
import history from '~/services/history';
import { tokenizeBank } from '~/services/zoop';

// admin

export function* adminGetUser({ payload }) {
  try {
    const { setUser, setBalance, username } = payload;

    const { data } = yield call(api.get, `/admin/users/${username}`);
    setUser(data.user);
    setBalance(data.balance);

    yield put(UserActions.adminGetUserSuccess());
  } catch (err) {
    yield put(UserActions.failure({ status: err?.response?.status }));
  }
}

export function* adminCreateUser({ payload }) {
  try {
    const { user } = payload;

    const infoERP = {
      RA_bank: user?.RA_bank,
      store: user?.store,
      RA_branch: user?.RA_branch,
      branch: user?.branch,
      RA_account: user?.RA_account,
    };

    yield call(api.post, `/admin/users`, {
      ...user,
      infoERP,
    });

    toast.success('Usuário criado com sucesso');
    yield put(UserActions.adminCreateUserSuccess());
    history.goBack();
  } catch (err) {
    if (err?.response?.data?.message === 'Unexpected error.') {
      toast.error('Erro ao criar o usuário');
    } else {
      toast.error(
        err?.response?.data?.message || 'Ops... Parece que ocorreu algum erro.'
      );
    }
    yield put(UserActions.failure({ status: err?.response?.status }));
  }
}

export function* adminUpdateUser({ payload }) {
  const notification = toast.loading('Atualizando dados...');
  try {
    const { user } = payload;

    yield call(api.put, `/admin/users/details/${user?.id}`, {
      ...user,
    });

    toast.update(notification, {
      render: 'Usuário atualizado com sucesso',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      autoClose: 5000,
    });
    yield put(UserActions.adminUpdateUserSuccess());
  } catch (err) {
    toast.update(notification, {
      render: 'Erro ao atualizar o usuário',
      type: toast.TYPE.ERROR,
      isLoading: false,
      autoClose: 5000,
    });
    yield put(UserActions.failure({ status: err?.response?.status }));
  }
}

export function* adminUpdateUserIntegrations({ payload }) {
  const notification = toast.loading('Atualizando dados...');
  try {
    const { paymentTypes, providerPlans, userCredentials, id } = payload;

    yield call(api.put, `/admin/users/integrations/${id}`, {
      paymentTypes,
      providerPlans: providerPlans.length > 0 ? providerPlans : null,
      userCredentials,
    });

    toast.update(notification, {
      render: 'Usuário atualizado com sucesso',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      autoClose: 5000,
    });
    yield put(UserActions.adminUpdateUserIntegrationsSuccess());
  } catch (err) {
    toast.update(notification, {
      render: 'Erro ao atualizar o usuário',
      type: toast.TYPE.ERROR,
      isLoading: false,
      autoClose: 5000,
    });
    yield put(UserActions.failure({ status: err?.response?.status }));
  }
}

export function* getUsers({ payload }) {
  try {
    const { setUsers } = payload;
    const { data } = yield call(api.get, `/admin/users`);

    setUsers(data);

    yield put(UserActions.getUsersSuccess());
  } catch (err) {
    yield put(UserActions.failure({ status: err?.response?.status }));
  }
}

// default

export function* getUser() {
  try {
    const { data } = yield call(api.get, '/profile');

    yield put(
      UserActions.getUserSuccess({
        balance: data.balance,
        user: data.user,
      })
    );
  } catch (err) {
    yield put(UserActions.failure({ status: err?.response?.status }));
  }
}

export function* search({ payload }) {
  const { setUsers } = payload;

  try {
    const { username } = payload;

    const { data } = yield call(api.get, `/user?username=${username}`);

    setUsers(data);

    yield put(UserActions.searchSuccess());
  } catch (err) {
    setUsers([]);
    yield put(UserActions.failure({ status: err?.response?.status }));
  }
}

export function* submitDocument({ payload }) {
  const { file, category } = payload;

  try {
    const formData = new FormData();

    formData.append('category', category);
    formData.append('file', file);

    yield call(api.put, '/sellers/documents', formData);

    toast.success('Documento enviado com sucesso.');
    yield put(UserActions.submitDocumentSuccess());
  } catch (err) {
    toast.error(
      'Ops... Parece que ocorreu algum erro. Verifique os dados e/ou tente novamente mais tarde.'
    );

    yield put(UserActions.failure({ status: err?.response?.status }));
  }
}

export function* addBankAccount({ payload }) {
  const { accountNumber, bankCode, branch, type } = payload;
  let token;

  try {
    const profile = yield select((state) => state.user.profile);

    const bankAccount = yield tokenizeBank({
      holderName: profile.fullName,
      accountNumber,
      bankCode,
      routingNumber: branch,
      taxpayerId: profile.doc,
      type,
    });

    token = bankAccount.id;
  } catch (err) {
    yield put(UserActions.failure());
  }

  if (token) {
    try {
      const { data } = yield call(api.post, '/bank_account', {
        token,
      });

      yield put(UserActions.addBankAccountSuccess(data));
      history.go(0);
      toast.success('Conta bancária cadastrada com sucesso.');
    } catch (err) {
      toast.error(
        'Ops... Parece que ocorreu algum erro. Verifique os dados e/ou tente novamente mais tarde.'
      );
      yield put(UserActions.failure());
    }
  }
}

export function* failure({ payload }) {
  const { status } = payload;

  if (status === 401) {
    yield put(AuthActions.authFailure({ status }));
  }
}

export function* updateAccount({ payload }) {
  const formData = new FormData();
  formData.append('fullName', payload.data.fullName);
  formData.append('email', payload.data.email);
  formData.append('phone', payload.data.phone);
  formData.append('dateBirth', payload.data.dateBirth);
  formData.append('doc', payload.data.doc);
  formData.append('postCode', payload.data.postCode);
  formData.append('state', payload.data.state);
  formData.append('city', payload.data.city);
  formData.append('neighborhood', payload.data.neighborhood);
  formData.append('street', payload.data.street);
  formData.append('addressNumber', payload.data.addressNumber);
  formData.append('complement', payload.data.complement);
  if (payload.data.password) {
    formData.append('password', payload.data.password);
    formData.append('oldPassword', payload.data.oldPassword);
  }

  if (payload.data.file) {
    formData.append('file', payload.data.file);
  }

  try {
    const { data } = yield call(api.put, '/users', formData);

    yield put(UserActions.updateAccountSuccess(data));
    toast.success('Perfil atualizado!');
  } catch (err) {
    toast.error(
      'Ops... Parece que ocorreu algum erro. Tente novamente mais tarde.'
    );
    yield put(UserActions.failure());
  }
}

export default all([
  takeLatest('@user/ADMIN_GET_USER_REQUEST', adminGetUser),
  takeLatest('@user/ADMIN_CREATE_USER_REQUEST', adminCreateUser),
  takeLatest('@user/ADMIN_UPDATE_USER_REQUEST', adminUpdateUser),
  takeLatest(
    '@user/ADMIN_UPDATE_USER_INTEGRATIONS_REQUEST',
    adminUpdateUserIntegrations
  ),
  takeLatest('@user/GET_USER_REQUEST', getUser),
  takeLatest('@user/SEARCH_REQUEST', search),
  takeLatest('@user/ADMIN_GET_USERS_REQUEST', getUsers),
  takeLatest('@user/SUBMIT_DOCUMENT_REQUEST', submitDocument),
  takeLatest('@user/ADD_BANK_ACCOUNT_REQUEST', addBankAccount),
  takeLatest('@user/UPDATE_ACCOUNT_REQUEST', updateAccount),
  takeLatest('@user/FAILURE', failure),
]);
